.footer-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0C1524;
    padding: 0.75rem 0 0.5rem 0;
    justify-content: space-around;
    z-index: 100;
    position: relative;
}

.footer-Container>:nth-child(1) {
    font-size: 12.5px;
    margin: 0 5rem;
}

.footer-Container>:nth-child(3) {
    font-size: 8px;
}


.footer-Container p {
    text-align: center;
    padding: 0.2rem 0 0;
    color: white;
    text-wrap: balance;
}



.seperator {
    margin: 0.5rem 0 0;
    width: 100%;
}

@media only screen and (max-width: 620px) {

    .footer-Container>:nth-child(1) {
        font-size: 2.5vw;
        margin: 0;


    }

    .footer-Container>:nth-child(3) {
        font-size: 8px;
    }
}