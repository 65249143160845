.content_experience-block {
    width: 55vw;
    height: fit-content;
    border-radius: 15px;
    background: #ffffffe6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem;
    margin: 1rem;
    display: flex;
    align-items: flex-start;
}

.company-logo_holder {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 10rem;
    margin: 0 1.5rem 0 0;
    border-radius: 25px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.company-logo_holder img {
    width: 10rem;
    padding: 1rem;
}

/* .company_details {
    text-align: justify;
} */

.company_header {
    font-size: 2rem;
    font-weight: bold;
    color: #0D1D2C;
    font-variant: petite-caps;
}

.company_timeframe {
    font-size: 14px;
    font-weight: bold;
    color: #505960;
}

.company_place_of_work {
    font-weight: bold;
    color: #0D1D2C;
}

@media only screen and (max-width: 620px) {
    .content_experience-block {
        width: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: fit-content;
        padding: 1rem;
        margin: 0.5rem;
    }

    .company-logo_holder img {
        width: 5rem;
    }

    .company-logo_holder {
        margin: 0 0 1.5rem 0;
        border-radius: 15px;
        width: 5rem;
        height: 5rem;
    }

    .company_header {
        font-size: 5vw;
        text-align: center;
    }

    .company_timeframe,
    .company_place_of_work {
        font-size: 3vw;
        text-align: center;
    }
}