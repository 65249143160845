  .cube_container {
    position: absolute;
    height: 200px;
    width: 200px;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-perspective: 400px;
    perspective: 400px;
  }

  .cube {
    height: 200px;
    width: 200px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: rotate 3s infinite ease-in-out;
    animation: rotate 3s infinite ease-in-out;

  }

  .side {
    position: absolute;
    display: block;
    height: 200px;
    width: 200px;
  }

  .side1 {
    background: #41C3AC;
    -webkit-transform: translateZ(100px);
    transform: translateZ(100px);
  }

  .side2 {
    background: #FF884D;
    -webkit-transform: rotateY(90deg) translateZ(100px);
    transform: rotateY(90deg) translateZ(100px);
  }

  .side3 {
    background: #32526E;
    -webkit-transform: rotateY(180deg) translateZ(100px);
    transform: rotateY(180deg) translateZ(100px);
  }

  .side4 {
    background: #65A2C5;
    -webkit-transform: rotateY(-90deg) translateZ(100px);
    transform: rotateY(-90deg) translateZ(100px);
  }

  .side5 {
    background: #FFCC5C;
    -webkit-transform: rotateX(90deg) translateZ(100px);
    transform: rotateX(90deg) translateZ(100px);
  }

  .side6 {
    background: #FF6B57;
    -webkit-transform: rotateX(-90deg) translateZ(100px);
    transform: rotateX(-90deg) translateZ(100px);
  }

  @-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotateX(0deg) rotateY(0deg);
    }

    40% {
      -webkit-transform: rotateX(375deg) rotateY(0deg);
    }

    47%,
    50% {
      -webkit-transform: rotateX(360deg) rotateY(0deg);
    }

    90% {
      -webkit-transform: rotateX(360deg) rotateY(375deg);
    }

    97%,
    100% {
      -webkit-transform: rotateX(360deg) rotateY(360deg);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }

    40% {
      transform: rotateX(375deg) rotateY(0deg);
    }

    47%,
    50% {
      transform: rotateX(360deg) rotateY(0deg);
    }

    90% {
      transform: rotateX(360deg) rotateY(375deg);
    }

    97%,
    100% {
      transform: rotateX(360deg) rotateY(360deg);
    }
  }