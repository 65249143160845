.projMainContainer {
    border-radius: 15px;
    background: #ffffffe6;
    margin: 3rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.tumbnailDiv {
    display: flex;
    justify-content: center;
    margin: 1rem;
    background-color: #ffffff00;
}

.tumbnailDiv a {
    display: flex;
    width: 15rem;
}

.tumbnailDiv img {
    box-shadow: 0 4px 4px 0 #00000040;
    width: 15rem;
}

.mainDetails>div {
    text-align: center;
    font-size: 1rem;
    margin: 0.25rem;
}

.mainDetails>div>p {
    text-align: justify;
}

.mainDetails h4 {
    font-variant: small-caps;
    font-size: 34px;
}

.videoSourceContainer {
    width: 15rem;
    display: flex;
    justify-content: space-evenly;
}

@media only screen and (max-width: 620px) {

    .mainDetails>div {
        font-size: 3vw;
    }

    .projMainContainer {
        height: fit-content;
        flex-direction: column;
        align-items: normal;
    }

    .mainDetails {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mainDetails h4 {
        font-size: 5vw;
    }

    .stackImages img,
    .videoSourceContainer img {
        height: 1rem;
    }

}