.skills_logo-holder {
    margin: 6vw 1.5vw;
    width: 7rem;
    height: 7rem;
    border-radius: 15px;
    background: #ffffffe6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: center;
    display: flex;

}

.skills_logo-holder img {
    width: 4rem;
}

@media only screen and (max-width: 620px) {

    .skills_logo-holder {
        margin: 1rem;
        width: 5rem;
        height: 5rem;
        border-radius: 15px;
    }

    .skills_logo-holder img {
        width: 3.5rem;
    }
}